import React, {useEffect, useState, useMemo, useRef} from "react";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { apiUrl, assetsUrl } from "../../config/config";
import moment from 'moment';
import Spinner from "react-activity/dist/Spinner";
import Select from 'react-select';
import "react-activity/dist/Spinner.css";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const Promotions = () => {
  const generateRandomString = () => {
    const array = new Uint8Array(16); // 16 bytes
    window.crypto.getRandomValues(array);
    return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
}
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const [showloding, setshowloding] = useState(false);
    const [showmodal, setshowmodal] = useState(false);
    const [editshowmodal, seteditshowmodal] = useState(false);
    const [selectedid, setselectedid] = useState(0);
    const [categoriesdropdowndata, setcategoriesdropdowndata] = useState([]);

    const today = new Date().toISOString().split('T')[0];

    const [productslistdata, setproductslistdata] = useState([]);
    const [selectedproducts, setselectedproducts] = useState([]);
    
    const [discounturl, setdiscounturl] = useState(generateRandomString());

    const [categorydata, setcategorydata] = useState([]);
    const [subcategorydata, setsubcategorydata] = useState([]);
    const [promoname, setpromoname] = useState("");
    const [discountpercent, setdiscountpercent] = useState("");
    const [selectedcategory, setselectedcategory] = useState("");
    const [selectedsubcategory, setselectedsubcategory] = useState("");
    const [startdate, setstartdate] = useState("");
    const [enddate, setenddate] = useState("");
    const [promocode, setpromocode] = useState("");
    const [buy, setbuy] = useState("");
    const [get, setget] = useState("");
    const [usage, setusage] = useState("");
    const [usagepercustomer, setusagepercustomer] = useState("");
    const [priority, setpriority] = useState("")

    const maincategoryselectRef = useRef("");
    maincategoryselectRef.current = selectedcategory;


    const [name, setname] = useState("");
    const [type, settype] = useState("Movie");

    const selectedidRef = useRef(0);
    selectedidRef.current = selectedid;



    const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const paginationPageSizeSelector = useMemo(() => {
    return [10, 20, 50, 100];
  }, []);

  const submithandle = async () => {

    var selectedproductsfiltered = "";
    if(selectedproducts?.length > 0) {
      var dataarr = [];
      var prevdata = selectedproducts
      for(var i = 0; i < prevdata?.length; i++) {
        dataarr.push(prevdata[i].value);
      }
      selectedproductsfiltered = dataarr.join();
    } else {
      selectedproductsfiltered = ""
    }

      setshowloding(true);
      var data = {
        promotionName: promoname,
        discountValue: discountpercent,
        startDate: startdate,
        endDate: enddate,
        promocode: promocode,
        buy: buy,
        get: get,
        category: selectedcategory,
        subcategory: selectedsubcategory,
        category_product_name: selectedproductsfiltered,
        promotionUsage: usage,
        promotionUsagePerCustomer: usagepercustomer,
        priority: priority
    };
    console.log('data >>', data);
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "addnewpromotion", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            setshowmodal(false);
            setselectedproducts([]);
              setproductslistdata([]);
            setpromoname("");
            setdiscountpercent("");
            setselectedcategory("");
            setselectedsubcategory("");
            setstartdate("");
            setenddate("");
            setpromocode("");
            setpriority("");
            setbuy("");
            setget("");
            setusage("");
            setusagepercustomer("");
            fetchallcourses();
            setshowloding(false);
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
  };

  const edithandle = async () => {
    var selectedproductsfiltered = "";
    if(selectedproducts?.length > 0) {
      var dataarr = [];
      var prevdata = selectedproducts
      for(var i = 0; i < prevdata?.length; i++) {
        dataarr.push(prevdata[i].value);
      }
      selectedproductsfiltered = dataarr.join();
    } else {
      selectedproductsfiltered = ""
    }
        setshowloding(true);
        var data = {
          id: selectedidRef.current,
          promotionName: promoname,
        discountValue: discountpercent,
        startDate: startdate,
        endDate: enddate,
        promocode: promocode,
        buy: buy,
        get: get,
        category: selectedcategory,
        subcategory: selectedsubcategory,
        category_product_name: selectedproductsfiltered,
        promotionUsage: usage,
        promotionUsagePerCustomer: usagepercustomer,
        priority: priority
      };
      console.log('submithandle >>', data);
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .post(apiUrl + "updatepromotion", data, headers)
          .then((res) => {
            // console.log('res >>>', res.data)
            if (res.data.status == "success") {
              seteditshowmodal(false);
              setselectedproducts([]);
              setproductslistdata([]);
              setpromoname("");
              setdiscountpercent("");
              setselectedcategory("");
              setselectedsubcategory("");
              setstartdate("");
              setenddate("");
              setpromocode("");
              setpriority("");
              setbuy("");
              setget("");
              setusage("");
              setusagepercustomer("");
              setselectedid(0);
              fetchallcourses();
              setshowloding(false);
            } else {
              alert(res.data.message);
              setshowloding(false);
            }
          })
          .catch((err) => {
            setshowloding(false);
            alert(err.message);
          });
        setshowloding(false);
    };

    const clickhandle = () =>  {
        // console.log('Mission Launched')
      }

      const editlocation = async(obj) => {
        console.log('obj >>', obj);
        setpromoname(obj.promotionName);
        setdiscountpercent(obj.discountValue);
        setselectedcategory(obj.category);
        setselectedsubcategory(obj.subcategory);
        setstartdate(obj.startDate);
        setenddate(obj.endDate);
        setpromocode(obj.promocode);
        setpriority(obj.priority);
        setbuy(obj.buy);
        setget(obj.get);
        setusage(obj.promotionUsage);
        setusagepercustomer(obj.promotionUsagePerCustomer);
        setselectedid(obj.id);
        var prevproducts = obj.Products;
        if(prevproducts) {
          var prevarr = prevproducts.split(',');
          console.log('prevarr >>',prevarr);
          var newarr = [];
          for(var i = 0; i < prevarr?.length; i++) {
            var obj = {};
              obj["label"] = prevarr[i];
              obj["value"] = prevarr[i];
              newarr.push(obj);
          } 
          setselectedproducts(newarr)
        } else {
          setselectedproducts([])
        }
        // setname(name);
        // settype(type);
        seteditshowmodal(true);
      }

      const deletecourse = async (id, title) => {
        console.log('id >>', id);
        const result = window.confirm("Are you sure you want to delete this promotion");
        if (result) {
          var data = JSON.stringify({
            id: id,
          });
          const headers = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + userdata?.token,
            },
          };
          let resp = await axios
            .post(apiUrl + "deletepromotion", data, headers)
            .then((res) => {
              if (res.data.status == "success") {
                fetchallcourses();
              } else {
              }
            })
            .catch((err) => {
              alert(err.message);
            });
        } else {
          // User clicked Cancel or closed the dialog
          // console.log("User clicked Cancel");
        }
      };

      const defaultColDef = {
        // flex: 1,
        resizable: true,
        sortable: true 
      };


    const fetchallcourses = async () => {
        setshowloding(true);
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .get(apiUrl + "getallpromotion", headers)
          .then((res) => {
            if (res.data.status == "success") {
              // console.log('getallcategories >>', res.data);
              var prevdata = res.data.data;
              if(prevdata?.length > 0) {
                var columnsarr = [];
                var hidecolumns = ['id', 'created_at', 'updated_at', 'sort', 'image', 'category_id', 'discountValue', 'appliedTo', 'promocode', 'buy', 'get', 'category', 'category_product_name', 'promotionUsage', 'promotionUsagePerCustomer', 'priority', 'status'];
                var hidefilters = [];
                var columns = Object.keys(prevdata[0]);
                // console.log('columns  >>>', columns );
                for(var i = 0; i < columns?.length;i++) {
                  if(!hidecolumns.includes(columns[i])) {
                    var obj = {};
                    obj.field = columns[i];
                    if (!hidefilters.includes(columns[i])) {
                        obj.filter = true;
                        obj.filterParams = {
                          filterOptions: ["contains"],
                          maxNumConditions: 1,
                        };
                      }
                    console.log('obj >>', obj)
                    columnsarr.push(obj);
                  }
                }

                //Image
                if(columns.includes('image')) {
                  var imageobj = 
              {
                field: "Image",
                headerName: "Image",
                cellRenderer: (props) => {
                  // console.log('image props >>', props);
                  return (
                    <Link
                    to={assetsUrl+'sub-categories/'+props?.data?.image}
                    target="_blank"
                    style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      className="sidebaricons"
                      style={{ color: "blue" }}
                    />
                  </Link>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(imageobj);
                }

                //created_at
            //     if(columns.includes('created_at')) {
            //       var craetddatobj = 
            //   {
            //     field: "Date",
            //     headerName: "Date",
            //     cellRenderer: (props) => {
            //       // console.log('date props >>', props);
            //       return (
            //              <div>
            //               <p className="created_at_value">{moment(props?.data?.created_at).format('DD MMMM YYYY HH:mm')}</p>
            //             </div>
            //       )
            //     },
            //     cellRendererParams: {
            //       onClick: clickhandle,
            //     },
            //   }
            //   columnsarr.push(craetddatobj);
            //     }

             //View
            //  var viewobj = 
            //  {
            //    field: "view",
            //    headerName: "View details",
            //    cellRenderer: (props) => {
                 
            //      return (
            //        <div
            //              onClick={() => {
            //                editlocation(props?.data);
            //              }}
            //              style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
            //            >
            //              <FontAwesomeIcon
            //                icon={faEye}
            //                className="sidebaricons"
            //                style={{ color: "blue" }}
            //              />
            //            </div>
            //      )
            //    },
            //    cellRendererParams: {
            //      onClick: clickhandle,
            //    },
            //  }
            //  columnsarr.push(viewobj);
              //edit
              var editobj = 
              {
                field: "edit",
                headerName: "Edit",
                cellRenderer: (props) => {
                  
                  return (
                    <div
                          onClick={() => {
                            editlocation(props?.data);
                          }}
                          style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                        >
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className="sidebaricons"
                            style={{ color: "blue" }}
                          />
                        </div>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(editobj);
               //delete
               var deleteobj = 
               {
                 field: "delete",
                 headerName: "Delete",
                 cellRenderer: (props) => {
                   
                   return (
                     <div
                           onClick={() => {
                             deletecourse(props?.data?.id);
                             // console.log('props >>', props?.data);
                           }}
                           style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                         >
                           <FontAwesomeIcon
                             icon={faTrash}
                             className="sidebaricons"
                             style={{ color: "red" }}
                           />
                         </div>
                   )
                 },
                 cellRendererParams: {
                   onClick: clickhandle,
                 },
               }
               columnsarr.push(deleteobj);
               setColDefs(columnsarr);
               setRowData(prevdata);
             }
              
                setColDefs(columnsarr);
                setRowData(prevdata);
              
              // setcoursesdata(res.data.data);
            } else {
              // alert(res.data.message);
            }
          })
          .catch((err) => {
            alert(err.message);
          });
          setshowloding(false);
      };

      const fetchcategories = async() => {
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .get(apiUrl + "getallcategories", headers)
          .then((res) => {
            if (res.data.status == "success") {
              var alldata = res.data.data;
              console.log('category alldata >>', alldata);
              var prevarr = [];
              for(var i = 0; i < alldata?.length; i++) {
                var obj = {};
                obj['value'] = alldata[i]['id'];
                obj['label'] = alldata[i]['name'];
                prevarr.push(obj);
              }
              setcategorydata(prevarr);
            } else {
  
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      }

    const fetchsubcategories = async(subid) => {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      var data = {
        id: subid
      }
      let resp = await axios
        .post(apiUrl + "getfetchsubcategoriesbyid",data, headers)
        .then((res) => {
          if (res.data.status == "success") {
            var alldata = res.data.data;
            console.log('alldata >>', alldata);
            if(alldata?.length > 0) {
              var newarr = []
              for(var i = 0; i < alldata?.length; i++) {
                var obj = {};
                obj['value'] = alldata[i]['slug'];
                obj['label'] = alldata[i]['name'];
                newarr.push(obj)
              }
              setsubcategorydata(newarr);
            } else {
              setsubcategorydata([]);
            }
            
            
            // console.log('seperateobj >>', seperateobj);
          } else {

          }
        })
        .catch((err) => {
          alert(err.message);
        });
    }

    const fetchproductsbysubcat = async(subid) => {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      var data = {
        subcategory: subid
      }
      let resp = await axios
        .post(apiUrl + "fetchproductsbysubcategory",data, headers)
        .then((res) => {
          if (res.data.status == "success") {
            console.log("getallproducts >>", res.data);
          if (res?.data?.data?.length > 0) {
            var prevdata = res?.data?.data;
            var newarr = [];
            for (var i = 0; i < prevdata?.length; i++) {
              var obj = {};
              obj["label"] = prevdata[i]?.slug;
              obj["value"] = prevdata[i]?.slug;
              
              newarr.push(obj);
            }
            setproductslistdata(newarr);
          }
            
            
            // console.log('seperateobj >>', seperateobj);
          } else {

          }
        })
        .catch((err) => {
          alert(err.message);
        });
    }

    useEffect(() => {
      if(selectedsubcategory) {
        console.log('selectedsubcategory >>', selectedsubcategory);
        fetchproductsbysubcat(selectedsubcategory)
      }
    }, [selectedsubcategory])

    useEffect(() => {
      // console.log('maincategoryselectRef?.current >>>', maincategoryselectRef?.current);
      if(maincategoryselectRef?.current) {
        // setselectmainsubcategory("")
        fetchsubcategories(maincategoryselectRef?.current)
      }
    }, [maincategoryselectRef?.current]);
    useEffect(() => {
        if (userdata && Object.keys(userdata).length > 0) {
          //   fetchdata();
          fetchallcourses();
          fetchcategories();
        //   fetchallproducts();
        } else {
          navigate("/newadminlogin");
        }
      }, []);
    return (
      <div className="adminmainpagelayout">
        <div className="admin-categorymaindiv">
          <p className="admin-page-title">View All Promotions</p>
          <div
            className="category-add-cat-btn"
            onClick={() => {
              setselectedproducts([]);
              setproductslistdata([]);
            setpromoname("");
            setdiscountpercent("");
            setselectedcategory("");
            setselectedsubcategory("");
            setstartdate("");
            setenddate("");
            setpromocode("");
            setpriority("");
            setbuy("");
            setget("");
            setusage("");
            setusagepercustomer("");
            setselectedid(0);
              setdiscounturl(generateRandomString());
              setshowmodal(true);
            }}
          >
            <p>Add Promotion</p>
          </div>
        </div>

        <div
          className={"ag-theme-quartz"}
          style={{ width: "100%", height: 500, marginTop: 15 }}
        >
          <AgGridReact
            // modules={AllModules}
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            rowSelection={"multiple"}
            pagination={true}
            paginationPageSize={10}
            paginationPageSizeSelector={paginationPageSizeSelector}
            // onGridReady={onGridReady}
          />
        </div>

        {/*Add modal */}
        <Modal
          show={showmodal}
          onHide={() => setshowmodal(false)}
          size="md"
          className="custom-width-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: 19 }}>Add Promotion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="webadmin-modalmainbody">
              {/* <div className="">
                    <label className="admin-label">Select Product</label>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      value={selectedproducts}
                      onChange={setselectedproducts}
                      options={productslistdata}
                    />
                  </div> */}
              {/* <div className="row mt-3">
                    <div className="col-md-6">
                    <div className="">
                    <label className="admin-label">End Date</label>
                        <input
                              value={enddate}
                              onChange={(t) => {
                                setenddate(t.target.value);
                              }}
                              type="date"
                              min={today}
                              className="form-control admininput"
                            />
                  </div>
                    </div>
                    <div className="col-md-6">
                    <div className="">
                    <label className="admin-label">Discount(%)</label>
                        <input
                              value={discountpercent}
                              onChange={(t) => {
                                setdiscountpercent(t.target.value);
                              }}
                              type="number"
                              className="form-control admininput"
                            />
                  </div>
                    </div>
                    <div className="col-md-12 mt-3">
                    <div className="">
                    <label className="admin-label">URL</label>
                        <input
                              value={discounturl}
                              onChange={(t) => {
                                setdiscounturl(t.target.value);
                              }}
                              type="text"
                              className="form-control admininput"
                            />
                  </div>
                    </div>
                  </div> */}

              <div className="row">
                <div className="col-md-8">
                  <div className="">
                    <label className="admin-label">Promotion Name</label>
                    <input
                      value={promoname}
                      onChange={(t) => {
                        setpromoname(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="">
                    <label className="admin-label">Discount(%)</label>
                    <input
                      value={discountpercent}
                      onChange={(t) => {
                        setdiscountpercent(t.target.value);
                      }}
                      type="number"
                      className="form-control admininput"
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Category</label>
                   <select className="form-control admininput" value={selectedcategory} onChange={(t) => {setselectedcategory(t.target.value)}} >
                    <option value={""} disabled>select category</option>
                    {
                      categorydata?.length > 0 ?
                      categorydata?.map((item, index) => {
                        return (
                          <option value={item.value}>{item.label}</option>
                        )
                      }) : null
                    }
                   </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Sub Category</label>
                    <select className="form-control admininput" value={selectedsubcategory} onChange={(t) => {setselectedsubcategory(t.target.value)}}>
                      <option value={""} disabled>select sub category</option>
                      {
                        subcategorydata?.length > 0 ?
                        subcategorydata?.map((item, index) => {
                        return (
                          <option value={item.value}>{item.label}</option>
                        )
                      }) : null
                    }
                   </select>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                    <label className="admin-label">Select Product</label>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      value={selectedproducts}
                      onChange={setselectedproducts}
                      options={productslistdata}
                    />
                  </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Start Date</label>
                    <input
                      value={startdate}
                      onChange={(t) => {
                        setstartdate(t.target.value);
                      }}
                      min={today}
                      type="date"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">End Date</label>
                    <input
                      value={enddate}
                      onChange={(t) => {
                        setenddate(t.target.value);
                      }}
                      type="date"
                      min={startdate ? startdate : today}
                      className="form-control admininput"
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Promotion Promocode</label>
                    <input
                      value={promocode}
                      onChange={(t) => {
                        setpromocode(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Promotion Priority</label>
                    <input
                      value={priority}
                      onChange={(t) => {
                        setpriority(t.target.value);
                      }}
                      type="number"
                      className="form-control admininput"
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Buy</label>
                    <input
                      value={buy}
                      onChange={(t) => {
                        setbuy(t.target.value);
                      }}
                      type="number"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Get</label>
                    <input
                      value={get}
                      onChange={(t) => {
                        setget(t.target.value);
                      }}
                      type="number"
                      className="form-control admininput"
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Promotion Usage</label>
                    <input
                      value={usage}
                      onChange={(t) => {
                        setusage(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Promotion Usage Per Customer</label>
                    <input
                      value={usagepercustomer}
                      onChange={(t) => {
                        setusagepercustomer(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                    />
                  </div>
                </div>
              </div>

              <div
                className="webadmin-modalbtn"
                onClick={() => {
                  submithandle();
                }}
              >
                <p>
                  {showloding ? (
                    <Spinner size={15} className="spinnerCenter" />
                  ) : (
                    "Submit"
                  )}
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/*Edit modal */}
        <Modal
          show={editshowmodal}
          onHide={() => seteditshowmodal(false)}
          size="md"
          className="custom-width-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: 19 }}>Edit Promotion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="webadmin-modalmainbody">
            <div className="row">
                <div className="col-md-8">
                  <div className="">
                    <label className="admin-label">Promotion Name</label>
                    <input
                      value={promoname}
                      onChange={(t) => {
                        setpromoname(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="">
                    <label className="admin-label">Discount(%)</label>
                    <input
                      value={discountpercent}
                      onChange={(t) => {
                        setdiscountpercent(t.target.value);
                      }}
                      type="number"
                      className="form-control admininput"
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Category</label>
                   <select className="form-control admininput" value={selectedcategory} onChange={(t) => {setselectedcategory(t.target.value)}} >
                    <option value={""} disabled>select category</option>
                    {
                      categorydata?.length > 0 ?
                      categorydata?.map((item, index) => {
                        return (
                          <option value={item.value}>{item.label}</option>
                        )
                      }) : null
                    }
                   </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Sub Category</label>
                    <select className="form-control admininput" value={selectedsubcategory} onChange={(t) => {setselectedsubcategory(t.target.value)}}>
                      <option value={""} disabled>select sub category</option>
                      {
                        subcategorydata?.length > 0 ?
                        subcategorydata?.map((item, index) => {
                        return (
                          <option value={item.value}>{item.label}</option>
                        )
                      }) : null
                    }
                   </select>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                    <label className="admin-label">Select Product</label>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      value={selectedproducts}
                      onChange={setselectedproducts}
                      options={productslistdata}
                    />
                  </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Start Date</label>
                    <input
                      value={startdate}
                      onChange={(t) => {
                        setstartdate(t.target.value);
                      }}
                      min={today}
                      type="date"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">End Date</label>
                    <input
                      value={enddate}
                      onChange={(t) => {
                        setenddate(t.target.value);
                      }}
                      type="date"
                      min={startdate ? startdate : today}
                      className="form-control admininput"
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Promotion Promocode</label>
                    <input
                      value={promocode}
                      onChange={(t) => {
                        setpromocode(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Promotion Priority</label>
                    <input
                      value={priority}
                      onChange={(t) => {
                        setpriority(t.target.value);
                      }}
                      type="number"
                      className="form-control admininput"
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Buy</label>
                    <input
                      value={buy}
                      onChange={(t) => {
                        setbuy(t.target.value);
                      }}
                      type="number"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Get</label>
                    <input
                      value={get}
                      onChange={(t) => {
                        setget(t.target.value);
                      }}
                      type="number"
                      className="form-control admininput"
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Promotion Usage</label>
                    <input
                      value={usage}
                      onChange={(t) => {
                        setusage(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Promotion Usage Per Customer</label>
                    <input
                      value={usagepercustomer}
                      onChange={(t) => {
                        setusagepercustomer(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                    />
                  </div>
                </div>
              </div>

              <div
                className="webadmin-modalbtn"
                onClick={() => {
                  edithandle();
                }}
              >
                <p>
                  {showloding ? (
                    <Spinner size={15} className="spinnerCenter" />
                  ) : (
                    "Submit"
                  )}
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
}

export default Promotions