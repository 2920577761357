import React, { useState, useRef } from "react";
import axios from "axios";
import { apiUrl, assetsUrl } from "../config/config";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import Overlay from '../components/Overlay';

const Register = () => {
  const [errors, seterrors] = useState({name: false, mobile: false, email: false, password: false});
  const [name, setname] = useState("");
  const [number, setnumber] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [forcestateupdate, setforcestateupdate] = useState(false);
  const [isfocus, setisfocus] = useState({name: false, mobile: false, email: false, password: false});

  const location = useLocation();

  const { state } = location;
  console.log('register state >>', state);

  const navigate = useNavigate();

  const errorRef = useRef({});
  errorRef.current = errors;

  const isfocusRef = useRef({});
  isfocusRef.current = isfocus;

  const handleblur = (name) => {
    var prevdata = isfocusRef.current;
    prevdata[name] = true;
    setisfocus(prevdata);
    setforcestateupdate(!forcestateupdate);
  }

  const handlecreateaccount = () => {
    let passdata = { fromscreen: state?.fromscreen };
    navigate('/login', {state: passdata});
  }

  const handleregister = async() => {
    if(name && number && email && password) {
      var data = {
        name: name,
        number: number,
        email: email,
        password: password
      }
      const headers = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let resp = await axios
            .post(apiUrl + "customerregister",data, headers)
            .then((res) => {
              // console.log('getdashboarddata res >>', res.data)
              if(res?.data?.status == 'success') {
                alert('Registered successfully!! Please login to continue.');
                let passdata = { fromscreen: state?.fromscreen };
                navigate('/login', {state: passdata});
                // console.log('data >>', res?.data);
                // setgenredata(res?.data?.data);
  
                // console.log('products length >>', res?.data?.products?.length);
              }
            })
            .catch((err) => {
              alert(err.message);
            });
      console.log('data >>', data);
    } else {
      alert('Fill all required details');
    }
  }

  return (
    <>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6 mx-auto">
            <div className="border-secondary">
              <div className="card-header">
                <h3 className="mb-0 my-2 text-black">Create Account</h3>
              </div>
              <div className="login-card-body">
                <div className="form-group mb-2">
                    <label for="your_name" className="loginlabel">
                    Your name
                    </label>
                    <input
                    value={name}
                    onChange={(t) => {setname(t.target.value)}}
                    onBlur={() => {handleblur('name')}}
                      type="text"
                      className="form-control valid fs22"
                      id="your_name"
                      name="your_name"
                     
                    />
                    {
                        isfocusRef.current['name'] && !name ?
                        <p style={{color: 'red'}}>Enter name</p> : null
                    }
                    
                  </div>

                  <div className="form-group mb-2">
                    <label for="your_password" className="loginlabel">
                    Mobile number
                    </label>
                    <input
                    value={number}
                    onChange={(t) => {setnumber(t.target.value)}}
                    onBlur={() => {handleblur('number')}}
                      type="number"
                      className="form-control valid fs22"
                    />
                    {
                        isfocusRef.current['number'] && !number ?
                        <p style={{color: 'red'}}>Enter number</p> : null
                    }
                  </div>


                  <div className="form-group mb-2">
                    <label for="email_username" className="loginlabel">
                      Email
                    </label>
                    <input
                    value={email}
                    onChange={(t) => {setemail(t.target.value)}}
                    onBlur={() => {handleblur('email')}}
                      type="email"
                      className="form-control valid fs22"
                    />
                    {
                        isfocusRef.current['email'] && !email ?
                        <p style={{color: 'red'}}>Enter Email</p> : null
                    }
                  </div>

                  <div className="form-group mb-2">
                    <label for="email_username" className="loginlabel">
                      Password
                    </label>
                    <input
                    value={password}
                    onChange={(t) => {setpassword(t.target.value)}}
                    onBlur={() => {handleblur('password')}}
                      type="password"
                      className="form-control valid fs22"
                    />
                     {
                        isfocusRef.current['password'] && !password ?
                        <p style={{color: 'red'}}>Enter Password</p> : null
                    }
                  </div>
                  <p className="mb-3">
                     We will send you a text to verify your phone. Message and Data rates may apply.
                  </p>

                  <button className="loginbtn" onClick={() => {handleregister()}}>Submit</button>

                  <p className="">
                  Already have an account?
                  <span style={{cursor: 'pointer', color: 'rgb(13,110,253)'}} onClick={() => {handlecreateaccount()}}> Sign in</span>
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
