import Listingcards from "./Listingcards";
import axios from "axios";
import { apiUrl, assetsUrl } from "../config/config";
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from "react";
import ReactPaginate from 'react-paginate';
import Overlay from '../components/Overlay';
import Spinner from "react-activity/dist/Spinner";

const Listing = () => {
  const { subcategory, category } = useParams();
  const [genredata, setgenredata] = useState([]);
  const [selectedgenre, setselectedgenre] = useState("");
  const [selectedlanguage, setselectedlanguage] = useState("");
  const [selectsearch, setselectsearch] = useState("");
  const [subcategorydata, setsubcategorydata] = useState([]);

  const [productsdata, setproductsdata] = useState([]);
  const [showloding, setshowloding] = useState(false);
  const navigate = useNavigate();

  const [currentpage, setcurrentpage] = useState(0);
  const currentpageRef = useRef(0);
  currentpageRef.current = currentpage;

  const productsRef = useRef(0);
  productsRef.current = productsdata;

  //pagination
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [itemsPerPage, setitemsPerPage] = useState(8);
  const [currentItems, setcurrentItems] = useState(0);

  useEffect(() => {
    if(productsdata.length > 0) {
      setcurrentpage(0);
      const newOffset = (0 * itemsPerPage) % productsdata.length;
    var catedata = productsdata.slice(newOffset, newOffset + itemsPerPage)
    setcurrentItems(productsdata.slice(newOffset, newOffset + itemsPerPage));
    setpageCount(Math.ceil(productsdata.length / itemsPerPage));
    setItemOffset(newOffset);
    }
    
  }, [itemsPerPage])


  const handlePageClick = (event) => {
    console.log('handlePageClick event >>', event);
    // console.log('old itemOffset >>', itemOffset);
    // console.log('itemsPerPage >>', itemsPerPage);
    // console.log('productsdata?.length >>', productsdataRef.current?.length);
    setcurrentpage(event.selected);
    const newOffset = (event.selected * itemsPerPage) % productsRef.current?.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setcurrentItems(productsRef.current?.slice(newOffset, newOffset + itemsPerPage));
    setpageCount(Math.ceil(productsRef.current.length / itemsPerPage));
    setItemOffset(newOffset);
  };

  const handlechangepagecount = (num) => {
    setitemsPerPage(parseInt(num))
  }

  const Items = ({ currentItems }) => {
    console.log('currentItems11 >>', currentItems.length);
    return (
      <>
      {
        currentItems?.length > 0 ?
        currentItems?.map((item, index) => {
                // console.log('item >>', item)
                var proimage = null;
                if(item?.image1) {
                  proimage = item?.image1;
                } else if(item?.image2) {
                  proimage = item?.image2;
                } else if(item?.image3) {
                  proimage = item?.image3;
                } else if(item?.image4) {
                  proimage = item?.image4;
                } else {
                  proimage = null;
                }

                //percent
                var discountpercent = item?.discount_percent;
                var price = item?.price;
                if(discountpercent > 0) {
                 var discountprice = price * discountpercent / 100;
                } else {
                  var discountprice = 0;
                }
                return (
                  <div className="col-md-3" key={index}>
                  <div style={{cursor: 'pointer'}} onClick={() => {handlenavigation(item)}}>
                    <div className="card-img">
                      <div className="align-items-center d-flex justify-content-center img-border">
                        <img class="img-fluid" src={assetsUrl + 'products/'+proimage} />
                      </div>
                      <div className="card-img-text img-text">
                        <p class="price listing_title" style={{color: '#000'}}>
                          <span class="artist_name">{item?.composer}</span><br />
                          <span class="product_name">{item.name}</span><br />
                          <span class="formate_name">{item?.formate} </span>&nbsp;
                          <span class="product-price">₹{item?.price - parseInt(discountprice)}</span> 
                          {
                            discountpercent > 0 ? 
                            <span class="clr">
                            <s>₹{item?.price}</s>
                          </span> : null
                          }
                         
                          {
                            discountpercent > 0 ?
                            <span className="offer"><br />{discountpercent}% OFF</span> : null
                          }
                          
                        </p>
                        <div class="price-detail price-box">
                  {
                    discountprice > 0 ?
                    <span class="dicount-amount">Save ₹{parseInt(discountprice)}</span> :
                    null
                  }
                                

                                  {
                                    item?.stock < 1 ?
                                    <span style={{backgroundColor: '#e61513', color: '#fff', padding: 5,
                                      position: 'absolute', bottom: -212, right: -216, fontSize: 15
                                    }} >SOLD OUT</span> : null
                                  }
                                  
                                  </div>
                      </div>
                    </div>
                  </div>
                </div>
                  
                )
              })
            : 
            showloding ?
            <Spinner color='#f8a41a' size={30} className='spinnerCenteroffer' /> :

            <p class="price" style={{color: '#000'}}>
            <span class="artist_name">No Products</span>

          </p>
      }
      
      </>
    );
  }


  const fetchdashboarddata = async(subcat) => {
    setshowloding(true);
    const data = {
      subcategory: subcat,
      category: category
    }
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
          .post(apiUrl + "getgenrelist",data, headers)
          .then((res) => {
            // console.log('getdashboarddata res >>', res.data)
            if(res?.data?.status == 'success') {
              console.log('data >>', res?.data);
              setgenredata(res?.data?.data);

              // console.log('products length >>', res?.data?.products?.length);
            }
          })
          .catch((err) => {
            alert(err.message);
          });
    setshowloding(false);
    
  }

  const filterproducts = async(genre, language, text) => {
    var data = {
      subcategory: subcategory,
      category: category,
      genre: genre,
      language: language,
      searchtext: text
    }
    console.log('test data >>', data)
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
          .post(apiUrl + "fetchproductsbyfilter",data, headers)
          .then((res) => {
            // console.log('getdashboarddata res >>', res.data)
            if(res?.data?.status == 'success') {
              console.log('products length >>', res?.data?.products?.length);
              
              var catdata = res?.data?.products
              setcurrentItems(catdata.slice(itemOffset, itemsPerPage));
          setpageCount(Math.ceil(catdata.length / itemsPerPage));
          setproductsdata(res?.data?.products);
          setItemOffset(0);
            } else {
              setproductsdata([]);
            }
          })
          .catch((err) => {
            alert(err.message);
          });
    setshowloding(false);
  }

  const handlenavigation = (data) => {
    console.log('data >>', data)
    navigate('/entertainment/'+data?.slug)
  }

  useEffect(() => {
    filterproducts(selectedgenre, selectedlanguage, selectsearch);
  }, [selectedgenre, selectedlanguage, selectsearch, subcategory])


  useEffect(() => {
    // console.log('subcategory >>',subcategory)
    fetchdashboarddata(subcategory);
  }, [subcategory])

  return (
    <section className="pd50">
      <div className="container">
        <h1 className="pageheading mt20">Indian Vinyl Records</h1>
        {
          !showloding ?
          <>
             <div className="dflex space-between dblock">
          <div className="selectbox dflex dblock">
            <select
              className="lang uppercase"
              value={selectedgenre}
              onChange={(t) => {
                setselectedgenre(t.target.value);
              }}
            >
              <option value={""} selected>
                All GENRE
              </option>
              {genredata?.length > 0
                ? genredata?.map((item, index) => {
                    return (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })
                : null}
            </select>

            <select
              className="lang ml30 uppercase"
              value={selectedlanguage}
              onChange={(t) => {
                setselectedlanguage(t.target.value);
              }}
            >
              <option value={""} selected>
                All LANGUAGE
              </option>
              <option value="HINDI">HINDI</option>
              <option value="MARATHI">MARATHI</option>
              <option value="ENGLISH">ENGLISH</option>
              <option value="GUJRATI">GUJRATI</option>
              <option value="MALAYALAM">MALAYALAM</option>
              <option value="TAMIL">TAMIL</option>
              <option value="KANNADA">KANNADA</option>
              <option value="BENGALI">BENGALI</option>
              <option value="PUNJABI">PUNJABI</option>
              <option value="BHOJPURI">BHOJPURI</option>
              <option value="TELUGU">TELUGU</option>
              <option value="SANSKRIT">SANSKRIT</option>
            </select>
          </div>

          <div className="searchbox">
              <input
                value={selectsearch}
                onChange={(t) => {
                  setselectsearch(t.target.value)
                }}
                type="search"
                className="searchboxinput"
                placeholder="Search"
              />
          </div>
        </div>

        <div className="row justify-content-center">
          <Items currentItems={currentItems} />
          {/* {
        productsdata?.length > 0 ?
        productsdata?.map((item, index) => {
                // console.log('item >>', item)
                var proimage = null;
                if(item?.image1) {
                  proimage = item?.image1;
                } else if(item?.image2) {
                  proimage = item?.image2;
                } else if(item?.image3) {
                  proimage = item?.image3;
                } else if(item?.image4) {
                  proimage = item?.image4;
                } else {
                  proimage = null;
                }

                //percent
                var discountpercent = item?.discount_percent;
                var price = item?.price;
                if(discountpercent > 0) {
                 var discountprice = price * discountpercent / 100;
                } else {
                  var discountprice = 0;
                }
                return (
                  <div className="col-md-3" key={index}>
                  <div style={{cursor: 'pointer'}} onClick={() => {handlenavigation(item)}}>
                    <div className="card-img">
                      <div className="align-items-center d-flex justify-content-center img-border">
                        <img class="img-fluid" src={assetsUrl + 'products/'+proimage} />
                      </div>
                      <div className="card-img-text img-text">
                        <p class="price" style={{color: '#000'}}>
                          <span class="artist_name">{item?.composer}</span><br />
                          <span class="product_name">{item.name}</span><br />
                          <span class="formate_name">{item?.formate} </span>&nbsp;
                          <span class="product-price">₹{item?.price - parseInt(discountprice)}</span> 
                          {
                            discountpercent > 0 ? 
                            <span class="clr">
                            <s>₹{item?.price}</s>
                          </span> : null
                          }
                         
                          {
                            discountpercent > 0 ?
                            <span className="offer"><br />{discountpercent}% OFF</span> : null
                          }
                          
                        </p>
                        <div class="price-detail price-box">
                  {
                    discountprice > 0 ?
                    <span class="dicount-amount">Save ₹{parseInt(discountprice)}</span> :
                    null
                  }
                                

                                  {
                                    item?.stock < 1 ?
                                    <span style={{backgroundColor: '#e61513', color: '#fff', padding: 5,
                                      position: 'absolute', bottom: -212, right: -216, fontSize: 15
                                    }} >SOLD OUT</span> : null
                                  }
                                  
                                  </div>
                      </div>
                    </div>
                  </div>
                </div>
                  
                )
              })
            : 
            <p class="price" style={{color: '#000'}}>
            <span class="artist_name">No Products for this filter</span>

          </p>
            } */}
        </div>

        <div className="paginatemaindiv">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">>"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<<"
            renderOnZeroPageCount={null}
            className="custompaginate"
            forcePage={currentpage}
          />
          <select
            class="form-select paginatedropdown"
            value={itemsPerPage}
            onChange={(t) => {
              handlechangepagecount(t.target.value);
            }}
          >
            <option value="8">8</option>
            <option value="12">12</option>
            <option value="24">24</option>
            <option value="48">48</option>
          </select>
        </div>
          </> :
          <Spinner color='#f8a41a' size={30} className='spinnerCenteroffer' />
        }
       

       

      </div>
    </section>
  );
};

export default Listing;
