import React, { useEffect, useState, useMemo, useRef,useCallback } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrash,
  faEye,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { apiUrl, assetsUrl } from "../../config/config";
import moment from "moment";
import Spinner from "react-activity/dist/Spinner";
import Select from "react-select";
import Overlay from "../common/Overlay";
import "react-activity/dist/Spinner.css";
ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

const AllOrders = () => {
  let navigate = useNavigate();
  const userdata = useSelector((store) => store.userReducer.userdata);
  const dealsdata = [
    { value: "1", label: "Best Deals of the day" },
    { value: "2", label: "Deal of the week" },
  ];

  const [showloding, setshowloding] = useState(true);
  const [showmodal, setshowmodal] = useState(false);
  const [editshowmodal, seteditshowmodal] = useState(false);
  const [selectedid, setselectedid] = useState(0);
  const [categoriesdropdowndata, setcategoriesdropdowndata] = useState([]);

  const [productslistdata, setproductslistdata] = useState([]);
  const [selectedproducts, setselectedproducts] = useState([]);
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [discountpercent, setdiscountpercent] = useState("");
  const [alldealsdata, setalldealsdata] = useState(dealsdata);
  const [selecteddeal, setselecteddeal] = useState("");

  const [inputvaluechange, setinputvaluechange] = useState({});
  const [forceupdate, setforceupdate] = useState(false);
  const inputvaluechangeRef = useRef();
  inputvaluechangeRef.current = inputvaluechange;

  const [orderdetails, setorderdetails] = useState({});

  const orderdetailsRef = useRef();
  orderdetailsRef.current = orderdetails;

  const [trackingurl, settrackingurl] = useState("");

  const [name, setname] = useState("");
  const [type, settype] = useState("Movie");

  const selectedidRef = useRef(0);
  selectedidRef.current = selectedid;

  const [dropdownselectedcategory, setdropdownselectedcategory] = useState('All');

  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const paginationPageSizeSelector = useMemo(() => {
    return [10, 20, 50, 100];
  }, []);

  const clickhandle = () => {
    // console.log('Mission Launched')
  };

  const editlocation = async (id) => {
    console.log("id >>", id);
    fetchorderdetails(id);
  };

  const defaultColDef = {
    // flex: 1,
    resizable: true,
    sortable: true,
  };

  const handleupdateurlbtn = async(id) => {
    setshowloding(true);
    var data = {
      id: id,
      trackin_url: inputvaluechangeRef.current[id]
    };
    console.log("data >>", data);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .post(apiUrl + "updateordertrackingurl", data, headers)
      .then((res) => {
        // console.log('res >>>', res.data)
        if (res.data.status == "success") {
          alert('Tracking url updated!!')
          fetchallcourses(dropdownselectedcategory);
          setshowloding(false);
        } else {
          alert(res.data.message);
          setshowloding(false);
        }
      })
      .catch((err) => {
        setshowloding(false);
        alert(err.message);
      });
    setshowloding(false);
  }

  const handlechangeurl = (id, value) => {
    // console.log('value >>', value);
    var prevdata = inputvaluechangeRef.current;
    // prevdata[id] = value;
    
    if(prevdata[id]) {
      // console.log('found >>', prevdata[id]);
      prevdata[id] = value
    } else {
      // console.log('not found >>', prevdata[id]);
      prevdata[id] = value
    }
    // console.log('prevdata >>', prevdata);
    setinputvaluechange(prevdata);
    setforceupdate(!forceupdate)
  }

  const handlechangedeliverystatus = async(id, value) => {
    setshowloding(true);
    var data = {
      id: id,
      deliveryStatus: value
    };
    console.log("data >>", data);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .post(apiUrl + "updateorderdeliverystatus", data, headers)
      .then((res) => {
        // console.log('res >>>', res.data)
        if (res.data.status == "success") {
          fetchallcourses(dropdownselectedcategory);
          setshowloding(false);
        } else {
          alert(res.data.message);
          setshowloding(false);
        }
      })
      .catch((err) => {
        setshowloding(false);
        alert(err.message);
      });
    setshowloding(false);
  }

  const fetchorderdetails = async(id) => {
    setorderdetails({});
    settrackingurl("")
    var data = {
      id: id
    };
    console.log("data >>", data);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .post(apiUrl + "fetchorderdetails", data, headers)
      .then((res) => {
        console.log('res >>>', res.data)
        if (res.data.status == "success") {
          // fetchallcourses();
          if(res.data.data.length > 0) {
            setorderdetails(res.data.data[0]);
            settrackingurl(res.data.data[0].trackin_url)
            seteditshowmodal(true);
          } else {
            setorderdetails({});
            settrackingurl("");
          }
         
          setshowloding(false);
        } else {
          alert(res.data.message);
          setshowloding(false);
        }
      })
      .catch((err) => {
        setshowloding(false);
        alert(err.message);
      });
    setshowloding(false);
  }

  const fetchallcourses = async (status) => {
    setshowloding(true);
    var data = JSON.stringify({
      filterstatus: status,
    });
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .post(apiUrl + "getallorders",data, headers)
      .then((res) => {
        if (res.data.status == "success") {
          // console.log('getallcategories >>', res.data);
          var prevdata = res.data.data;
          if (prevdata?.length > 0) {
            var columnsarr = [];
            var hidecolumns = [
              "id",
              "created_at",
              "updated_at",
              "order_id",
              "order_details",
              "sort",
              "image",
              "category_id",
              "dealTypeId",
              "trackingUrl",
              "deliveryStatus",
              "userEmail",
              "UserAddress",
              "userState",
              "userCity",
              "userPincode",
              "userGst",
              "orderStatus",
              "orderCost",
              "invoice",
              "transactionId",
              "paymentOrderId",
              "signatureHash",
              "purchaseDate",
              "price",
              "PriceWithGst",
              "productTax",
              "ShippingName",
              "shippingEmail",
              "shippingMobile",
              "shippingGst",
              "shipping_address",
              "shippingState",
              "shippingCity",
              "shippingPincode",
              "preBooking"
            ];
            var hidefilters = [];
            var columns = Object.keys(prevdata[0]);
            console.log('columns  >>>', columns );
            for (var i = 0; i < columns?.length; i++) {
              if (!hidecolumns.includes(columns[i])) {
                var obj = {};
                obj.field = columns[i];
                if (!hidefilters.includes(columns[i])) {
                  
                  if(columns[i] == 'orderDate') {
                    obj.filter = 'agDateColumnFilter';
                    // obj.filterParams = filterParams;
                    obj.filterParams= {
                      comparator: function (filterDate, cellValue) {
                        // console.log('cellValue >>', cellValue);
                        // console.log('filterDate >>', filterDate);
                        const cellDate = new Date(cellValue);
                        const filterDateObj = new Date(filterDate);

                        // console.log('cellDate >>', cellDate);
                        // console.log('filterDateObj >>', filterDateObj);
                  
                        // if (cellDate < filterDateObj) {
                        //   return -1;
                        // } else if (cellDate > filterDateObj) {
                        //   return 1;
                        // } else {
                        //   return 0;
                        // }

                        // Remove time part by setting hours, minutes, and seconds to 0
      cellDate.setHours(0, 0, 0, 0);
      filterDateObj.setHours(0, 0, 0, 0);

      // Compare only the date part
      if (cellDate.getTime() === filterDateObj.getTime()) {
        return 0; // Dates are equal
      } else if (cellDate.getTime() < filterDateObj.getTime()) {
        return -1; // Cell date is before filter date
      } 
      else if (cellDate.getTime() > filterDateObj.getTime()) {
          return 1;
        }
        else {
        return 0; // Cell date is after filter date
      }
                      },
                      maxNumConditions: 1,
                    }
                  } else {
                    obj.filter = true;
                    obj.filterParams = {
                      filterOptions: ["contains"],
                      maxNumConditions: 1,
                    };
                  }
                }
                // console.log("obj >>", obj);
                columnsarr.push(obj);
              } else {
                var obj = {};
                 //hidden columns
                 obj.field = columns[i];
                 obj.hide = true;
                 columnsarr.push(obj);
              }
            }


            //created_at
            if (columns.includes("created_at")) {
              var craetddatobj = {
                field: "Date",
                headerName: "Date",
                cellRenderer: (props) => {
                  // console.log('date props >>', props);
                  return (
                    <div>
                      <p className="created_at_value">
                        {moment(props?.data?.created_at).format(
                          "DD MMMM YYYY HH:mm"
                        )}
                      </p>
                    </div>
                  );
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              };
              columnsarr.push(craetddatobj);
            }

            //tracking url
             var trackingobj = {
              field: "trackingobj",
              headerName: "Tracking Url",
              cellRenderer: (props) => {
                return (
                 <div className="admintrackingdiv">
                    <div className="admintrackingbox">
                        <input type="text" defaultValue={Object.keys(inputvaluechangeRef.current)?.length > 0 ? inputvaluechangeRef.current[props?.data?.id] : props?.data?.trackingUrl} value={null} onChange={(t) => {handlechangeurl(props?.data?.id, t.target.value)}} className="form-control admininput admintableinput" />
                        <button className="btn btn-primary updateurlbtn" onClick={() => {handleupdateurlbtn(props?.data?.id)}}>Update Url</button>
                    </div>
                 </div>
                );
              },
              cellRendererParams: {
                onClick: clickhandle,
              },
              width: 300
            };
            columnsarr.push(trackingobj);

            //delivery status
            // if(status == 'Failed') {
            //   var transactionobj = {
            //     field: "status",
            //     headerName: "Status",
            //     suppressCsvExport: true,
            //     cellRenderer: (props) => {
            //       // console.log('props >>', props?.data)
            //       return (
            //        <div className="admintrackingdiv">
            //           <div className="admintrackingbox">
            //           <input type="text" value={null} onChange={(t) => {handlechangeurl(props?.data?.id, t.target.value)}} className="form-control admininput admintableinput" />
            //           <button className="btn btn-primary updateurlbtn" onClick={() => {handleupdateurlbtn(props?.data?.id)}}>Update Url</button>
            //           </div>
            //        </div>
            //       );
            //     },
            //     cellRendererParams: {
            //       onClick: clickhandle,
            //     },
            //     width: 300
            //   };
            //   columnsarr.push(transactionobj);
            // } else {
            //   var statusobj = {
            //     field: "status",
            //     headerName: "Status",
            //     suppressCsvExport: true,
            //     cellRenderer: (props) => {
            //       // console.log('props >>', props?.data)
            //       return (
            //        <div className="admintrackingdiv">
            //           <div className="admintrackingbox">
            //               <select className="form-control admininput admintableinput" value={props?.data?.deliveryStatus} onChange={(t) => {handlechangedeliverystatus(props?.data?.id, t.target.value)}}>
            //                   <option value="Not Delivered">Unshipped</option>
            //                   <option value="Delivered" selected={props?.data?.deliveryStatus == 'Delivered' ? true : false} >Shipped</option>
            //               </select>
            //           </div>
            //        </div>
            //       );
            //     },
            //     cellRendererParams: {
            //       onClick: clickhandle,
            //     },
            //     width: 200
            //   };
            //   columnsarr.push(statusobj);
            // }
            var statusobj = {
              field: "status",
              headerName: "Status",
              suppressCsvExport: true,
              cellRenderer: (props) => {
                // console.log('props >>', props?.data)
                return (
                 <div className="admintrackingdiv">
                    <div className="admintrackingbox">
                        <select className="form-control admininput admintableinput" value={props?.data?.deliveryStatus} onChange={(t) => {handlechangedeliverystatus(props?.data?.id, t.target.value)}}>
                            <option value="Not Delivered">Unshipped</option>
                            <option value="Delivered" selected={props?.data?.deliveryStatus == 'Delivered' ? true : false} >Shipped</option>
                        </select>
                    </div>
                 </div>
                );
              },
              cellRendererParams: {
                onClick: clickhandle,
              },
              width: 200
            };
            columnsarr.push(statusobj);
            

            //edit
            var editobj = {
              field: "Details",
              headerName: "Details",
              suppressCsvExport: true,
              cellRenderer: (props) => {
                return (
                  <div
                    onClick={() => {
                      editlocation(props?.data?.id);
                      // console.log('props >>', props?.data);
                    }}
                    style={{
                      maxWidth: 35,
                      paddingLeft: 10,
                      paddingRight: 10,
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      className="sidebaricons"
                      style={{ color: "blue" }}
                    />
                  </div>
                );
              },
              width: 100
            };
            columnsarr.push(editobj);


            //delete
            // var deleteobj = {
            //   field: "delete",
            //   headerName: "Delete",
            //   cellRenderer: (props) => {
            //     return (
            //       <div
            //         onClick={() => {
            //           deletecourse(props?.data?.id);
            //           // console.log('props >>', props?.data);
            //         }}
            //         style={{
            //           maxWidth: 35,
            //           paddingLeft: 10,
            //           paddingRight: 10,
            //           cursor: "pointer",
            //         }}
            //       >
            //         <FontAwesomeIcon
            //           icon={faTrash}
            //           className="sidebaricons"
            //           style={{ color: "red" }}
            //         />
            //       </div>
            //     );
            //   },
            //   cellRendererParams: {
            //     onClick: clickhandle,
            //   },
            // };
            // columnsarr.push(deleteobj);


            setColDefs(columnsarr);
            setRowData(prevdata);
          }

          // setcoursesdata(res.data.data);
        } else {
          // alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    setshowloding(false);
  };

  useEffect(() => {
    console.log('dropdownselectedcategory >>', dropdownselectedcategory);
    fetchallcourses(dropdownselectedcategory);
  }, [dropdownselectedcategory])

  const gridRef = useRef();

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const getBoolean = (id) => {
    var field = document.querySelector("#" + id);
    return !!field.checked;
  };

  const getParams = () => {
    return {
      allColumns: true,
      // columnKeys: ['userName', 'userMobile', 'orderId', 'orderDate', 'trackingUrl', 'billingCost', 'deliveryStatus', 'status'],
      columnKeys: [
        "userName",
        "userMobile",
        "userEmail",
        "UserAddress",
        "userState",
        "userCity",
        "userPincode",
        "userGst",
        "order_details",
        "orderId",
        "orderDate",
        "trackingUrl",
        "billingCost",
        "deliveryStatus",
        "orderStatus",
        "orderCost",
        "invoice",
        "transactionId",
        "paymentOrderId",
        "signatureHash",
        "purchaseDate",
        "price",
        "PriceWithGst",
        "productTax",
        "ShippingName",
        "shippingEmail",
        "shippingMobile",
        "shippingGst",
        "shipping_address",
        "shippingState",
        "shippingCity",
        "shippingPincode",
        "preBooking"
    ],
      fileName: 'orders.csv',
      // Process each cell's value before exporting, especially for order_details
    processCellCallback: (params) => {
      if (params.column.getColId() === 'order_details') {
        // Format order_details as a string: 'Product: [name], Qty: [quantity]'
        if (Array.isArray(params.value)) {
          console.log('params.value >>', params.value);
          return params.value.map((detail, index) => `Product ${index + 1}: ${detail?.name}, Qty: ${detail?.qty}, Sku: ${detail?.skuid}`).join('\n');
        }
        return '';  // Return empty string if no order_details
      }
      return params.value;  // For other fields, return the default value
    }
    };
  };

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv(getParams());

  };

  const onBtnUpdate = useCallback(() => {
    console.log('gridRef >>', gridRef.current);
    document.querySelector("#csvResult").value =
      gridRef.current.api.getDataAsCsv();
  }, []);

  useEffect(() => {
    if (userdata && Object.keys(userdata).length > 0) {
      //   fetchdata();
      // fetchallcourses();
    } else {
      navigate("/newadminlogin");
    }
  }, []);
  return (
    <div className="adminmainpagelayout">
      {/* <Overlay showloader={true} /> */}
      <div className="admin-categorymaindiv">
        <p className="admin-page-title">View All Orders</p>
      </div>
      <div className="d-flex mt-4">
        <div className="admin-orders-pagediv">
          <select
            value={dropdownselectedcategory}
            className="form-control admininput"
            onChange={(t) => {
              setdropdownselectedcategory(t.target.value);
            }}
          >
            <option value={""} selected>
              Select Status
            </option>
            <option value={"All"}>All</option>
            <option value={"Shipped"}>Shipped</option>
            <option value={"Unshipped"}>Unshipped</option>
            <option value={"Failed"}>Generated Orders</option>
          </select>
        </div>
        <div className="table-exportcsv-row">
          <div className="exportcsv-div" onClick={onExportClick}>
            <FontAwesomeIcon
              icon={faDownload}
              className="exporticon"
              style={{ color: "black" }}
            />
            <p>Export</p>
          </div>
        </div>
        {/* <button onClick={onExportClick}>Export CSV</button> */}
      </div>

      <div
        className={"ag-theme-quartz"}
        style={{ width: "100%", height: 500, marginTop: 15 }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          suppressRowClickSelection={true}
          groupSelectsChildren={true}
          rowSelection={"multiple"}
          pagination={true}
          paginationPageSize={10}
          suppressExcelExport={true}
          popupParent={popupParent}
          paginationPageSizeSelector={paginationPageSizeSelector}
          // suppressCsvExport={true}
          // onGridReady={onGridReady}
        />
      </div>

      {/*Edit modal */}
      <Modal
        show={editshowmodal}
        onHide={() => seteditshowmodal(false)}
        size="xl"
        className="custom-width-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: 19 }}>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="webadmin-modalmainbody">
            {Object.keys(orderdetailsRef.current)?.length > 0 ? (
              <div className="row">
                {/* <div className="col-md-12">
                  <div className="admintrackingdiv">
                    <div className="admintrackingbox">
                      <input
                        type="text"
                        value={trackingurl}
                        onChange={(t) => {
                          settrackingurl(t.target.value)
                        }}
                        placeholder="Enter Tracking URL"
                        className="form-control admininput admintableinput"
                      />
                      <button
                        className="btn btn-primary updateurlbtn"
                        onClick={() => {
                          handleupdateurlbtn(orderdetailsRef.current.id);
                        }}
                      >
                        Update Tracking Url
                      </button>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-4 mt-3">
                  <div className="orderdetailsbox">
                    <p className="orderdetailsboxhead">Order Info</p>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">Order Id :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.order_id}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">Payment Status :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.order_status}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">Order Status :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.deliveryStatus}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">Billing Cost :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.billing_cost}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">Invoice :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.invoice}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">Transaction Id :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.transaction_id}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">Payment Order Id :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.payment_order_id}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">Purchase Date :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.purchase_date}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">Order Date :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.create_at}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-3">
                  <div className="orderdetailsbox">
                    <p className="orderdetailsboxhead">User Info</p>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">User Name :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.user_name}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">User Email :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.user_email}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">User Mobile :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.user_mobile}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">User Address :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.user_address}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">User City :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.user_city}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">User Pincode :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.user_pincode}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">User Gst :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.user_gst}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-3">
                  <div className="orderdetailsbox">
                    <p className="orderdetailsboxhead">Shipping Details</p>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">Shipping Name :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.shipping_name}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">Shipping Email :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.shipping_email}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">Shipping Mobile :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.shipping_mobile}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">Shipping GST :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.shipping_gst}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">Shipping Address :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.shipping_address}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">Shipping State :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.shipping_state}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">Shipping City :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.shipping_city}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">Shipping Pin Code :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.shipping_pincode}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">Tracking URL :</p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.trackin_url}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">
                        Complete Shipping Address :
                      </p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.shipping_address}
                      </p>
                    </div>
                    <div className="orderdetailsrow">
                      <p className="orderdetailskey">
                        Complete Billing Address :
                      </p>
                      <p className="orderdetailsvalue">
                        {orderdetailsRef.current?.shipping_address}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <div className="orderdetailsbox">
                    <p className="orderdetailsboxhead">Product Details</p>
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Quantity</th>
                          {/* <th>URL</th> */}
                          <th>Invoice</th>
                          <th>Order</th>
                          <th>SKU</th>
                          <th>HSN</th>
                          <th>Selling Price</th>
                          <th>Format</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderdetailsRef.current?.order_details?.length > 0
                          ? orderdetailsRef.current?.order_details?.map(
                              (item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item?.name}</td>
                                    <td>{item.qty}</td>
                                    {/* <td>View Product</td> */}
                                    <td><Link to={"/invoice/"+orderdetailsRef.current?.order_id} target="_blank">{orderdetailsRef.current?.invoice}</Link></td>
                                    <td>{item?.order_type}</td>
                                    <td>{item?.product?.seller_sku_id}</td>
                                    <td>{item.hsn_code}</td>
                                    <td>{item.price}</td>
                                    <td>{item?.product?.formate}</td>
                                  </tr>
                                );
                              }
                            )
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : showloding ? (
              <Spinner size={24} className="spinnerCenter2" />
            ) : (
              <p>Empty order details</p>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AllOrders;
