import  logo  from '../images/logo-header.png';
import  basking  from '../images/basking-shark-logo.png';
import {Link} from 'react-router-dom';

const Footer = () => {
  return (
    <>
    <footer>
      <div className="container">
        <div className="dflex space-between ptb30">
            <img src={logo} className='footer-logo' alt="" />

            <img src={basking} className='footer-logo' alt="" />
        </div>
  
        

      <div className="dflex space-between">
        <div className='footer-link'>
            <p className='uppercase ff'>SALES OFFICE:<br />
               GODOWN NO. 458,<br />
               JAI BHAWANI INDUSTRIAL ESTATE,<br />
               NEAR ST. XAVIER’S SCHOOL GATE,<br /> NO 4, GHODBUNDER ROAD,<br />
               CHITALSAR VILLAGE, MANPADA<br />
               THANE (WEST) – 400 607, INDIA.
            </p>
        </div>  

        <div className='footer-link m-none'>
            <ul>
                <li><Link to="/home" className='uppercase'>Home</Link></li>
                <li><Link to="/about-us" className='uppercase'>ABOUT US</Link></li>
                <li><Link to="/services" className='uppercase'>SERVICES</Link></li>
                <li><Link to="/music" className='uppercase'>MUSIC</Link></li>
                <li><Link to="/movies" className='uppercase'>MOVIES</Link></li>
                <li><Link to="/contact-us" className='uppercase'>CONTACT</Link></li>
            </ul>
        </div>  

        <div className='footer-link m-none'>
            <ul>
                <li><Link to="/" className='uppercase'>EXCLUSIVES</Link></li>
                <li><Link to="/" className='uppercase'>NEW RELEASES</Link></li>
                <li><Link to="/" className='uppercase'>UPCOMING RELEASES</Link></li>
                <li><Link to="/" className='uppercase'>IMPORTS</Link></li>
                <li><Link to="/" className='uppercase'>SITEMAP</Link></li>
            </ul>
            
        </div>  

        <div className='footer-link m-none'>
            <ul>
                <li><Link to="/" className='uppercase'><i className="fa fa-facebook"></i> <span>/BLISSTAINMENT</span></Link></li>
                <li><Link to="/" className='uppercase'><i className="fa fa-instagram"></i> <span>/BLISSTAINMENT</span></Link></li>
                <li><Link to="/" className='uppercase'><i className="fa fa-linkedin"></i> <span>/BLISSTAINMENT</span></Link></li>
                <li><Link to="/" className='uppercase'><i className="fa fa-twitter"></i> <span>/BLISSTAINMENT</span></Link></li>
                <li><Link to="/" className='uppercase'><i className="fa fa-phone"></i> <span>/+91 85910 10516</span></Link></li>
                <li><Link to="/" className='uppercase'><i className="fa fa-envelope"></i> <span>/info@blisstainment.comT</span></Link></li>
            </ul>
        </div>  

        <div className='footer-link'>
            <p className='uppercase ff'>
               REGISTERED OFFICE:<br />
               B-06, SATYAVANDAN,<br />
               KOLSHET ROAD,<br />
               THANE (WEST) 400 607,<br />
               INDIA.
            </p>
        </div>    
      </div>
      </div>  
    </footer> 

    <section style={{backgroundColor:'#808080cc', color:'#fff'}}>
        <div className='container'>
         <div className="dflex content-center fs13">
            <p className='border-left pd ff'>All rights reserved by @blisstainment.com</p>
            <p className='border-left pd ff'>Disclaimer</p>
            <p className='pd ff'>Privacy Policy</p>
        </div>
        </div>
    </section>

    </>
  )
}

export default Footer