import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import Omshasntiom from "../images/omshasntiom.png";
import Nushrat from "../images/nushrat.png";
import Pushpa from "../images/pushpa.png";
import Tridev from "../images/tridev.png";
import "swiper/css/navigation";
import "swiper/css/thumbs";
// import required modules
import { Autoplay, FreeMode, Navigation, Thumbs } from "swiper/modules";
import { apiUrl, assetsUrl } from "../config/config";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Overlay from '../components/Overlay';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { Button, Modal } from "react-bootstrap";

const ProductDetails = () => {
  // const { state } = useLocation();
  const { slug } = useParams();
  // console.log('slug >>', slug);
  const swiperRef = useRef(null);
  const swiperRefmodal = useRef(null);
  const [showloding, setshowloding] = useState(false);
  const [productddetails, setproductddetails] = useState({});
  const [offerdata, setofferdata] = useState({});
  const [relatedproducts, setrelatedproducts] = useState([]);
  const [discountpercent, setdiscountpercent] = useState(0);
  const [discountedprice, setdiscountedprice] = useState(0);
  const [showmodal, setshowmodal] = useState(false);

  const [quantity, setquantity] = useState(1);
  // console.log('state >>', state);
  // const [thumbsSwiper, setThumbsSwiper] = useState();
  const cartdata = useSelector((store) => store.userReducer.cartdata);

  const navigate = useNavigate();
  const dispatch = useDispatch(); 

  const DisplayOnlyText = (data) => {
    const parser = new DOMParser();
    var datas = data;
    const htmlString = data;
    return (
      <div dangerouslySetInnerHTML={{ __html: htmlString }} />
    );
  };


  const handleproductaddtocart = (data) => {
    var prevcartdata = cartdata;
    var newcartdata = [];
    var cartimage = "";
    if(data?.image1) {
      cartimage = data?.image1;
    } else if(data?.image2) {
      cartimage = data?.image2;
    } else if(data?.image3) {
      cartimage = data?.image3;
    } else if(data?.image4) {
      cartimage = data?.image4;
    } else {
      cartimage = "";
    }

    var cartobj = {
      id: data?.id,
      name: data?.name,
      cartimage: cartimage,
      price: productddetails?.price - discountedprice,
      quantity: quantity
    }
    // console.log('cartobj >>', cartobj);
    var checkstatus = false;
    for(var i = 0; i < prevcartdata?.length; i++) {
      if(prevcartdata[i].id == data?.id) {
        checkstatus = true;
        var prevobj = prevcartdata[i];
        // prevobj?.quantity =  prevobj?.quantity + 1;
        newcartdata.push(prevobj);
      } else {
        newcartdata.push(prevcartdata[i])
      }
    }
    if(!checkstatus) {
      newcartdata.push(cartobj);
    }
    dispatch({ type: "cartdata", payload: newcartdata });
  }

  const handleproductaddbuynow = (data) => {
    var prevcartdata = cartdata;
    var newcartdata = [];
    var cartimage = "";
    if(data?.image1) {
      cartimage = data?.image1;
    } else if(data?.image2) {
      cartimage = data?.image2;
    } else if(data?.image3) {
      cartimage = data?.image3;
    } else if(data?.image4) {
      cartimage = data?.image4;
    } else {
      cartimage = "";
    }

    var cartobj = {
      id: data?.id,
      name: data?.name,
      cartimage: cartimage,
      price: productddetails?.price - discountedprice,
      quantity: quantity
    }
    // console.log('cartobj >>', cartobj);
    var checkstatus = false;
    for(var i = 0; i < prevcartdata?.length; i++) {
      if(prevcartdata[i].id == data?.id) {
        checkstatus = true;
        var prevobj = prevcartdata[i];
        // prevobj?.quantity =  prevobj?.quantity + 1;
        newcartdata.push(prevobj);
      } else {
        newcartdata.push(prevcartdata[i])
      }
    }
    if(!checkstatus) {
      newcartdata.push(cartobj);
    }
    dispatch({ type: "cartdata", payload: newcartdata });
    navigate('/checkout');
  }

  const fetchproductdetailsbyslug = async(slug) => {
    setshowloding(true);
    var data = {
      slug: slug
  };
  const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    let resp = await axios
    .post(apiUrl + "getproductdetails",data, headers)
    .then((res) => {
      // console.log('res >>', res.data)
      if(res?.data?.productdata?.length > 0) {
        setproductddetails(res?.data?.productdata[0]);
      } else {
        setproductddetails([]);
      }

      if(res?.data?.offers?.length > 0) {
        setofferdata(res?.data?.offers[0]);
      } else {
        setofferdata([]);
      }

      if(res?.data?.relatedproducts?.length > 0) {
        setrelatedproducts(res?.data?.relatedproducts);
      } else {
        setrelatedproducts([]);
      }



     //check if percent is there in offers else consider from product details
     var offerdataa = res?.data?.offers[0];
     var prodataa = res?.data?.productdata[0];

     console.log('offerdataa >>', offerdataa);
     console.log('discountprice >>', discountprice);

     var getdiscountpercent = 0;
     if(offerdataa?.discount_percent > 0) {
      getdiscountpercent = offerdataa?.discount_percent;
     } else if(prodataa?.discountprice && prodataa?.discountprice > 0) {
      getdiscountpercent = prodataa?.discountprice;
     } else {
      getdiscountpercent = 0;
     }

     console.log('getdiscountpercent >>', getdiscountpercent);

     var price = prodataa?.price;
     if(getdiscountpercent > 0) {
      var discountprice = price * getdiscountpercent / 100;
     } else {
       var discountprice = 0;
     }
     setdiscountpercent(getdiscountpercent);
     setdiscountedprice(discountprice);

     })
     .catch((err) => {
      alert(err.message);
    });
    setshowloding(false);
  }

  const handlenavigation = (data) => {
    // console.log('data >>', data)
    // navigate('/productdetails', {state: {data: data}});
    navigate('/entertainment/'+data?.slug)
  }

  useEffect(() => {
    fetchproductdetailsbyslug(slug)
  }, [slug])

  // console.log('productddetails >>', productddetails);
  // console.log('offerdata >>>', offerdata);

  const goToSlide = (index) => {
    console.log('swiperRef.current >>', swiperRef.current.swiper)
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
      // setshowmodal(true);
    }
  };

  const goToSlide1 = (index) => {
    setshowmodal(true);
    setTimeout(() => {
      console.log('index >>', index);
    console.log('swiperRefmodal.current >>', swiperRefmodal?.current?.swiper)
    if (swiperRefmodal.current && swiperRefmodal.current.swiper) {
      swiperRefmodal.current.swiper.slideTo(index);
      // setshowmodal(true);
    }
    }, [100])
    
    
  }

  return (
    <>
    <Overlay showloader={showloding} />
    {
      showloding ?
      <div className="loaderscreenheight">

      </div> :
      <>
      <section className="pd50">
          <div className="container">
            <h2 className="detaipageheadig">{productddetails?.formate} {productddetails?.name}</h2>
            <div className="row">
              <div className="col-md-5">
                {/* <img src="https://www.blisstainment.com/assets/products/RR75141%204092001%20-%20COLOURED%20LP%2001.jpg" className='img-responsive' /> */}
  
                <Swiper
                ref={swiperRef}
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                  }}
                  spaceBetween={10}
                  freeMode={true}
                  watchSlidesProgress={true}
                  navigation={true}
                  // thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper"

                >
                  {
                    productddetails?.image1 ?
                    <SwiperSlide>
                      <img src={assetsUrl+'products/'+productddetails?.image1} style={{cursor: 'pointer'}} onClick={() => {goToSlide1(0)}} />
                  </SwiperSlide> : null
                  }
                  {
                    productddetails?.image2 ?
                    <SwiperSlide>
                    <img src={assetsUrl+'products/'+productddetails?.image2} style={{cursor: 'pointer'}} onClick={() => {goToSlide1(1)}} />
                  </SwiperSlide> : null
                  }
                  {
                    productddetails?.image3 ?
                    <SwiperSlide>
                    <img src={assetsUrl+'products/'+productddetails?.image3} style={{cursor: 'pointer'}} onClick={() => {goToSlide1(2)}} />
                  </SwiperSlide> : null
                  }
                  {
                    productddetails?.image4 ?
                    <SwiperSlide>
                    <img src={assetsUrl+'products/'+productddetails?.image4} style={{cursor: 'pointer'}} onClick={() => {goToSlide1(3)}} />
                  </SwiperSlide> : null
                  }
                </Swiper>
                 <br />
  
  
                {/* tumbnails */}
                <Swiper
                  //    onSwiper={setThumbsSwiper}
                  spaceBetween={10}
                  slidesPerView={4}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  {
                    productddetails?.image1 ?
                    <SwiperSlide>
                    <img src={assetsUrl+'products/'+productddetails?.image1} style={{cursor: 'pointer'}} onClick={() => {goToSlide(0)}} />
                  </SwiperSlide> : null
                  }
                  {
                    productddetails?.image2 ?
                    <SwiperSlide>
                    <img src={assetsUrl+'products/'+productddetails?.image2} style={{cursor: 'pointer'}} onClick={() => {goToSlide(1)}} />
                  </SwiperSlide> : null
                  }
                  {
                    productddetails?.image3 ?
                    <SwiperSlide>
                    <img src={assetsUrl+'products/'+productddetails?.image3} style={{cursor: 'pointer'}} onClick={() => {goToSlide(2)}} />
                  </SwiperSlide> : null
                  }
                  {
                    productddetails?.image4 ?
                    <SwiperSlide>
                    <img src={assetsUrl+'products/'+productddetails?.image4} style={{cursor: 'pointer'}} onClick={() => {goToSlide(3)}} />
                  </SwiperSlide> : null
                  }
                </Swiper>
              </div>
  
              <div className="col-md-7">
                <div className="product-content">
                  <h3 className="text-black fontweight600">
                    {productddetails?.artist}
                  </h3>
                  <h3 className="text-black fontweight600">
                  {productddetails?.name}
                  </h3>
  
                  <div>
                    <span className="product-price-details">₹{productddetails?.price - discountedprice}</span>{" "}
                    {
                      discountpercent > 0 ?
                      <span className="clr">
                      <s>₹{productddetails?.price}</s>
                    </span> :
                    null
                    }
                    {
                      discountpercent > 0 ?
                      <span className="offer"> &nbsp;&nbsp;{discountpercent}% off</span> : null
                    }
                    
                  </div>
  
                  <div className="contentdetails mt-3 mb-3">
                  {/* {DisplayOnlyText(productddetails?.description)} */}
                    <p className="mb-3 productdescription">
                      {DisplayOnlyText(productddetails?.description)}
                      {/* {productddetails?.description} */}
                    </p>
                    <label className="labeldetails">Quantity: </label>{" "}
                    <input value={quantity} onChange={(t) => {setquantity(t.target.value)}} type="number" defaultValue="1" className="quantitynumber" min="1" max="10" />
                  </div>
                  <div className="dflex mt-5 dblock">
                    {
                      Object.keys(productddetails)?.length > 0 ?
                      <button className="buynow" onClick={() => {handleproductaddbuynow(productddetails)}}>Buy Now</button> :
                      null
                    }
                    
                    {
                      
                      cartdata && Object.keys(cartdata)?.length > 0 && cartdata?.some(obj => obj.id === productddetails?.id) ?
                      <button className="buynow" style={{cursor: 'unset'}} disabled>Added</button> :
                      <button className="buynow" onClick={() => {handleproductaddtocart(productddetails)}}>Add to Cart</button>
                    }
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr />
  
        <div className="container mb-5">
          <h2 className="mb-5 weight600">
            Customers who bought this item also bought
          </h2>
          <Swiper
            slidesPerView={6}
            breakpoints={{
              320: {
                width: 320,
                slidesPerView: 2,
              },
            }}
            spaceBetween={20}
            freeMode={true}
            loop={true}
            modules={[FreeMode, Autoplay]}
            className="mySwiper"
          >
            {
              relatedproducts?.map((item, index) => {
                var proimage = null;
                if(item?.image1) {
                  proimage = item?.image1;
                } else if(item?.image2) {
                  proimage = item?.image2;
                } else if(item?.image3) {
                  proimage = item?.image3;
                } else if(item?.image4) {
                  proimage = item?.image4;
                } else {
                  proimage = null;
                }
                if(item?.slug !== productddetails?.slug) {
                  return (
                    <SwiperSlide style={{cursor: 'pointer'}} onClick={() => {handlenavigation(item)}}>
                      <img src={assetsUrl+'products/'+proimage} />
                      <div className="img-text">
                        <h6 className="">{item?.name}</h6>
                        <h6>{item?.artist}</h6>
                      </div>
                    </SwiperSlide>
                  );
                }
                
              })
            }
            
  
          </Swiper>
        </div>
  
        <hr />
        
        {
          productddetails?.track_heading_1 || 
          productddetails?.track_heading_2 ||
          productddetails?.track_heading_3 ||
          productddetails?.track_heading_4 ||
          productddetails?.track_heading_5 ||
          productddetails?.track_heading_6 ||
          productddetails?.track_heading_7 ||
          productddetails?.track_heading_8 ?
          <>
            <div className="container mb-5">
          <h2 className="mb-5 weight600">Track Listing</h2>
          <div className="row">
            {
              productddetails?.track_heading_1 && productddetails?.track_content_1 ?
              <div className="col-md-3">
              <div className="sidea">
                <h3>{productddetails?.track_heading_1}</h3>
                   <ol>
                    {productddetails?.track_content_1?.split(',')?.map((item, index) => {
                      return (
                        <li key={index}>{item}</li>
                      )
                    })}
                    </ol>
              </div>
            </div> : null
            }
            {
              productddetails?.track_heading_2 && productddetails?.track_content_2 ?
              <div className="col-md-3">
              <div className="sidea">
                <h3>{productddetails?.track_heading_2}</h3>
                   <ol>
                    {productddetails?.track_content_2?.split(',')?.map((item, index) => {
                      return (
                        <li key={index}>{item}</li>
                      )
                    })}
                    </ol>
              </div>
            </div> : null
            }
            {
              productddetails?.track_heading_3 && productddetails?.track_content_3 ?
              <div className="col-md-3">
              <div className="sidea">
                <h3>{productddetails?.track_heading_3}</h3>
                   <ol>
                    {productddetails?.track_content_3?.split(',')?.map((item, index) => {
                      return (
                        <li key={index}>{item}</li>
                      )
                    })}
                    </ol>
              </div>
            </div> : null
            }
            {
              productddetails?.track_heading_4 && productddetails?.track_content_4 ?
              <div className="col-md-3">
              <div className="sidea">
                <h3>{productddetails?.track_heading_4}</h3>
                   <ol>
                    {productddetails?.track_content_4?.split(',')?.map((item, index) => {
                      return (
                        <li key={index}>{item}</li>
                      )
                    })}
                    </ol>
              </div>
            </div> : null
            }
            {
              productddetails?.track_heading_5 && productddetails?.track_content_5 ?
              <div className="col-md-3">
              <div className="sidea">
                <h3>{productddetails?.track_heading_5}</h3>
                   <ol>
                    {productddetails?.track_content_5?.split(',')?.map((item, index) => {
                      return (
                        <li key={index}>{item}</li>
                      )
                    })}
                    </ol>
              </div>
            </div> : null
            }
            {
              productddetails?.track_heading_6 && productddetails?.track_content_6 ?
              <div className="col-md-3">
              <div className="sidea">
                <h3>{productddetails?.track_heading_6}</h3>
                   <ol>
                    {productddetails?.track_content_6?.split(',')?.map((item, index) => {
                      return (
                        <li key={index}>{item}</li>
                      )
                    })}
                    </ol>
              </div>
            </div> : null
            }
            {
              productddetails?.track_heading_7 && productddetails?.track_content_7 ?
              <div className="col-md-3">
              <div className="sidea">
                <h3>{productddetails?.track_heading_7}</h3>
                   <ol>
                    {productddetails?.track_content_7?.split(',')?.map((item, index) => {
                      return (
                        <li key={index}>{item}</li>
                      )
                    })}
                    </ol>
              </div>
            </div> : null
            }
            {
              productddetails?.track_heading_8 && productddetails?.track_content_8 ?
              <div className="col-md-3">
              <div className="sidea">
                <h3>{productddetails?.track_heading_8}</h3>
                   <ol>
                    {productddetails?.track_content_8?.split(',')?.map((item, index) => {
                      return (
                        <li key={index}>{item}</li>
                      )
                    })}
                    </ol>
              </div>
            </div> : null
            }
            
  
            
          </div>
        </div>
        <hr />
          </> : null
        }
        
  
        <div className="container mb-5">
          <h2 className="mb-3 weight600" style={{ color: "#e6993d" }}>
            Product Details
          </h2>
  
          <ul className="productdetailslist">
            <li>
              <span>HSN Code : </span> <span> {productddetails?.code}</span>
            </li>
            <li>
              <span>Seller SKU ID : </span> <span> {productddetails?.seller_sku_id}</span>
            </li>
            <li>
              <span>Publisher : </span> <span> {productddetails?.publisher}</span>
            </li>
            <li>
              <span>Edition : </span> <span> {productddetails?.edition}</span>
            </li>
            <li>
              <span>Composer : </span> <span> {productddetails?.composer}</span>
            </li>
            <li>
              <span>EAN : </span> <span> {productddetails?.ean_upc}</span>
            </li>
            <li>
              <span>Artist : </span> <span> {productddetails?.artist}</span>
            </li>
            <li>
              <span>Country of origin : </span> <span> {productddetails?.origin}</span>
            </li>
            <li>
              <span>Record Label/Studio : </span> <span> {productddetails?.record_label_studio}</span>
            </li>
            <li>
              <span>Format : </span> <span> {productddetails?.formate}</span>
            </li>
            <li>
              <span>Number of disc : </span> <span> {productddetails?.no_of_disc}</span>
            </li>
            <li>
              <span>Language : </span> <span> {productddetails?.language}</span>{" "}
            </li>
          </ul>
        </div>

        {/*Add modal */}
    <Modal
              show={showmodal}
              onHide={() => setshowmodal(false)}
              size="lg"
              className="custom-width-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title style={{fontSize: 19}}></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="product-details-swipper-modal">
                <Swiper
                ref={swiperRefmodal}
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                  }}
                  spaceBetween={10}
                  navigation={true}
                  // thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper"
                >
                  {
                    productddetails?.image1 ?
                    <SwiperSlide>
                      <img src={assetsUrl+'products/'+productddetails?.image1} />
                  </SwiperSlide> : null
                  }
                  {
                    productddetails?.image2 ?
                    <SwiperSlide>
                    <img src={assetsUrl+'products/'+productddetails?.image2} />
                  </SwiperSlide> : null
                  }
                  {
                    productddetails?.image3 ?
                    <SwiperSlide>
                    <img src={assetsUrl+'products/'+productddetails?.image3} />
                  </SwiperSlide> : null
                  }
                  {
                    productddetails?.image4 ?
                    <SwiperSlide>
                    <img src={assetsUrl+'products/'+productddetails?.image4} />
                  </SwiperSlide> : null
                  }
                </Swiper>
                </div>
              </Modal.Body>
            </Modal>
      </>
    }
    
   

    </>
  );
};

export default ProductDetails;
