import React, { useEffect } from 'react';

const RazorpayBadge = () => {
  useEffect(() => {
    // Create the script tag for Razorpay's trusted badge
    const script = document.createElement('script');
    script.src = 'https://cdn.razorpay.com/widgets/trusted-badge.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      {/* Razorpay trusted business badge */}
      <razorpay-trusted-business key="rzp_live_DAyD5EuV7mxduw"></razorpay-trusted-business>
    </div>
  );
}

export default RazorpayBadge;