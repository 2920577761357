import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import { store } from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist'; //with persist

// pages
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';
import Music from './pages/Music';
import Movies from './pages/Movies';
import Deals from './pages/Deals';
import Listing from './pages/Listing';
import ProductDetails from './pages/ProductDetails';
import Cart from './pages/Cart';
import Login from './pages/Login';
import Register from './pages/Register';
import Myorder from './pages/Myorder';
import OrderHistory from './pages/OrderHistory';
import AdminLogin from './admin/pages/AdminLogin';
import AdminDashboard from './admin/pages/Dashboard';
import Categories from './admin/pages/Categories';
import SubCategories from './admin/pages/SubCategories';
import ShippingCharges from './admin/pages/ShippingCharges';
import Formats from './admin/pages/Formats';
import ProductFilters from './admin/pages/ProductFilters';
import Products from './admin/pages/Products';
import ProductOffers from './admin/pages/ProductOffers';
import ProductDeals from './admin/pages/ProductDeals';
import AllOrders from './admin/pages/AllOrders';
import Sliders from './admin/pages/Sliders';
import Users from './admin/pages/Users';
import Testimonials from './admin/pages/Testimonials';
import Feedbacks from './admin/pages/Feedbacks';
import Promotions from './admin/pages/Promotions';
import PageSeo from './admin/pages/PageSeo';
import HomepageProducts from './admin/pages/HomepageProducts';
import Blogs from './admin/pages/Blogs';
import BlogDetail from './pages/BlogDetail';

import Offers from './pages/Offers';
import Checkout from './pages/Checkout';
import BillingAddress from './pages/BillingAddress';
import Invoice from './pages/Invoice';

import Layouts from './admin/Layouts';
import AdminLayout from './admin/AdminLayout';

let persistor = persistStore(store); //with persis

const router = createBrowserRouter(


  createRoutesFromElements(
    <>
    
    <Route path="/" element={<App />}>
      <Route index element={<Home />} />
      <Route path='home' element={<Home />} />
      <Route path="about-us" element={<About />} />
      <Route path="services" element={<Services />} />
      <Route path="music" element={<Music />} />
      <Route path="movies" element={<Movies />} />
      {/* <Route path="listing" element={<Listing />} /> */}
      <Route path="entertainment/:slug" element={<ProductDetails />} />
      <Route path="deals" element={<Deals />} />
      <Route path="cart" element={<Cart />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="myorder" element={<Myorder />} />
      <Route path="orderhistory" element={<OrderHistory />} />
      <Route path="contact-us" element={<Contact />} />
      <Route path='offers/:link' element={<Offers />} />
      <Route path='sub-category/:subcategory/:category' element={<Listing />} />
      <Route path='checkout' element={<Checkout />} />
      <Route path='billing-address' element={<BillingAddress />} />
      <Route path='blog/:slug' element={<BlogDetail />} />
    </Route>

    <Route path='/invoice/:orderid' element={<Invoice />} />

    {/* adminlogin */}
    <Route path="/" element={<Layouts />}>
       <Route path='/admin' element={<AdminLogin />} />
       <Route path='/admin/login' element={<AdminLogin />} />
    </Route>

    <Route path="/" element={<AdminLayout />}>
      <Route path='/admin/home' element={<AdminDashboard />} />
      <Route path='/admin/dashboard' element={<AdminDashboard />} />
      <Route path='/admin/category' element={<Categories />} />
      <Route path='/admin/sub-category' element={<SubCategories />} />
      <Route path='/admin/shipping-charges' element={<ShippingCharges />} />
      <Route path='/admin/formats' element={<Formats />} />
      <Route path='/admin/genre' element={<ProductFilters />} />
      <Route path='/admin/product' element={<Products />} />
      <Route path='/admin/offers' element={<ProductOffers />} />
      <Route path='/admin/deals' element={<ProductDeals />} />
      <Route path='/admin/all-orders' element={<AllOrders />} />
      <Route path='/admin/sliders' element={<Sliders />} />
      <Route path='/admin/users' element={<Users />} />
      <Route path='/admin/testimonials' element={<Testimonials />} />
      <Route path='/admin/userFeedbackList' element={<Feedbacks />} />
      <Route path='/admin/promotions' element={<Promotions />} />
      <Route path='/admin/seo' element={<PageSeo />} />
      <Route path='/admin/homepage-products' element={<HomepageProducts />} />
      <Route path='/admin/blogs' element={<Blogs />} />
    </Route>
    
</>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Provider store={store}>
    <PersistGate persistor={persistor}>
    <RouterProvider router={router} />
    </PersistGate>
     </Provider>
  </>
);


