import React, { useState, useRef } from "react";
import axios from "axios";
import { apiUrl, assetsUrl } from "../config/config";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import Overlay from '../components/Overlay';

const Login = () => {
  const [name, setname] = useState("");
  const [password, setpassword] = useState("");
  const [forcestateupdate, setforcestateupdate] = useState(false);
  const [isfocus, setisfocus] = useState({name: false, password: false});
  const [showloding, setshowloding] = useState(false);

  const location = useLocation();

  const { state } = location;
  console.log('state >>', state);

  const isfocusRef = useRef({});
  isfocusRef.current = isfocus;

  const navigate = useNavigate();
  const dispatch = useDispatch(); 

  const handleblur = (name) => {
    var prevdata = isfocusRef.current;
    prevdata[name] = true;
    setisfocus(prevdata);
    setforcestateupdate(!forcestateupdate);
  }

  const handleregister = async() => {
    setshowloding(true);
    if(name && password) {
      var data = {
        name: name,
        password: password
      }
      const headers = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let resp = await axios
            .post(apiUrl + "customerlogin",data, headers)
            .then((res) => {
              // console.log('getdashboarddata res >>', res.data)
              if(res?.data?.status == 'success') {
                dispatch({ type: "customerdata", payload: res.data.data })
                if(state?.fromscreen == 'navbar') {
                  navigate('/myorder');
                } else {
                  navigate('/checkout');
                }
                
              } else {
                Swal.fire({
                  // title: 'Failed',
                  text: res.data.message,
                  icon: 'error',
                  customClass: {
                    icon: 'custom-swal-icon' // Apply the custom class
                  },
                  width: '400px',
                  confirmButtonText: 'Ok'
                });
              }
            })
            .catch((err) => {
              alert(err.message);

            });
      console.log('data >>', data);
    } else {
      alert('Fill all required details');
    }
    setshowloding(false);
  }

  const handlecreateaccount = () => {
    let passdata = { fromscreen: state?.fromscreen };
      navigate('/register', {state: passdata});
  }

  
  return (
    <>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6 mx-auto">
            <div className="border-secondary">
              <div className="card-header">
                <h3 className="mb-0 my-2 text-black">Login</h3>
              </div>
              <div className="login-card-body">
                  <div className="form-group mb-2">
                    <label for="email_username" className="loginlabel">
                      Email or Mobile number
                    </label>
                    <input
                    value={name}
                    onChange={(t) => {setname(t.target.value)}}
                    onBlur={() => {handleblur('name')}}
                      type="text"
                      className="form-control valid fs22"
                     
                    />
                    {
                        isfocusRef.current['name'] && !name ?
                        <p style={{color: 'red'}}>Enter Number or Email</p> : null
                    }
                  </div>

                  <div className="form-group mb-2">
                    <label for="email_username" className="loginlabel">
                      Password
                    </label>
                    <input
                    value={password}
                    onChange={(t) => {setpassword(t.target.value)}}
                    onBlur={() => {handleblur('password')}}
                      type="password"
                      className="form-control valid fs22"
                     
                    />
                    {
                        isfocusRef.current['password'] && !password ?
                        <p style={{color: 'red'}}>Enter Password</p> : null
                    }
                  </div>
                  <p className="mb-3">
                    By continuing, you agree to Blisstainment{" "}
                    <Link to="/">Conditions of Use </Link> and{" "}
                    <Link to="/">Privacy Notice.</Link>
                  </p>

                  <button className="loginbtn" onClick={() => {handleregister()}}>Submit</button>

                  <p className="mb-3">
                    <Link to="/">Forget Password</Link>
                  </p>
                  <p className="">
                    New to Blisstainment?{" "}
                    <span style={{cursor: 'pointer', color: 'rgb(13,110,253)'}} onClick={() => {handlecreateaccount()}}>Create Your Account</span>
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
