import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { assetsUrl, apiUrl } from "../config/config";
import Overlay from '../components/Overlay';

const Cart = () => {
  const customerdata = useSelector((store) => store.userReducer.customerdata);
  const cartdata = useSelector((store) => store.userReducer.cartdata);
  console.log('cart cartdata >>', cartdata);
  const [cart, setcart] = useState([]);
  const [totalprice, settotalprice] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch(); 

  const handlechangequantity = (index, quantity) => {
    if(quantity < 1) {

    } else {
      console.log('index >>', index);
    console.log('quantity >>', quantity);
    var prevdata = cart;
    prevdata[index].quantity = quantity;
    dispatch({ type: "cartdata", payload: prevdata });
    }
    
  }

  

  const handleremovecartitem = (index) => {
    var prevdata = cart;
    prevdata.splice(index, 1);
    dispatch({ type: "cartdata", payload: prevdata });
  }

  const handleplaceorder = () => {
    // console.log('customerdata >>', customerdata);
    if(customerdata && Object.keys(customerdata)?.length > 0) {
      navigate('/checkout');
    } else {
      // console.log('not logged in');
      let passdata = { fromscreen: 'cart' };
      navigate('/login', {state: passdata});
    }
  }

  useEffect(() => {
    // setcart(cartdata);
    var newarrobj = [];
    for(var i = 0; i < cartdata?.length > 0; i++) {
      var cartobj = {
        id: cartdata[i]?.id,
        name: cartdata[i]?.name,
        cartimage: cartdata[i]?.cartimage,
        price: cartdata[i]?.price,
        quantity: cartdata[i]?.quantity
      }
      newarrobj.push(cartobj)
    }
    setcart(newarrobj);
    // Calculate Total price
    var totalPrice = newarrobj.reduce((acc, item) => {
      return acc + item.price * parseInt(item.quantity);
    }, 0);
    settotalprice(totalPrice);
  }, [cartdata])
  return (
    <>
      <section className="mt-5 mb-3">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
            <h5 className="mb-4 carthead">Cart ({cart?.length} item)</h5>
            {
              cart?.length > 0 ?
              cart?.map((item, index) => {
                return (
                  <div className="productcartdetails">

                <div className="card-body dflex dblock">
                  <div className="viewimg">
                    <img
                      src={assetsUrl+"products/"+item?.cartimage}
                      className="img-fluid w-100"
                    />
                  </div>

                  <div className="dflex space-between w100">
                    <div className="cartcontent">
                      <h5 className="cart_title">{item?.name}</h5>
                      <p className="small uppercase">PRICE - ₹{item?.price}</p>
                      <p className="small uppercase">SHIPPING - 0</p>

                      <div style={{cursor: 'pointer'}} onClick={() => {handleremovecartitem(index)}}>
                        <p className="small mt-3" style={{color: 'red'}}>
                          <i className="fa fa-trash mr-1" style={{fontSize: 20}}></i>
                         <span style={{marginLeft: 7, fontSize: 17}}>Remove Item</span>
                        </p>
                      </div>
                    </div>
                    <div className="text-right">
                      <input
                      type="number"
                        value={item?.quantity}
                        defaultValue={item?.quantity}
                        onChange={(t) => {handlechangequantity(index, t.target.value)}}
                        className="quantitynumber"
                      />
                      <p className="small mb-2">(Note, 1 piece)</p>
                      <p className="">Total ₹{item?.price * item?.quantity} </p>
                    </div>
                  </div>
                </div>
              </div>
                )
              }) : null
            }
              
            </div>

            <div className="col-md-4">
            <h5 className="mb-4">&nbsp; </h5>
              <div className="productcartdetails">
                <h5 className="mb-4">Price Details</h5>
                <div className="dflex space-between mb-3">
                  <p className="fs16 weight600">Price ({cart?.length} items)</p>
                  <p className="fs16 weight600">₹{totalprice}</p>
                </div>

                <div className="dflex space-between">
                  <p className="fs16 weight600">Shipping Charges</p>
                  <p className="fs16 weight600">₹0</p>
                </div>

                <div className="">
                  <p className="fs16 weight600 mb-2">Apply Promocode:</p>
                  <input
                    type="text"
                    className="form-control cartinput"
                    placeholder="Apply Promocode"
                  />
                  <span className="text-green">Coupon Code Not Applied</span>
                </div>

                <div className="mt-4">
                   <button className="cta-apply">Apply</button>
                </div>

                <div className="dflex space-between mt-4">
                  <p className="fs16 weight600">Total Amount</p>
                  <p className="fs16 weight600">₹{totalprice}</p>

                </div>

                <div className="mt-1">
                   <button className="cta-danger" onClick={() => {
                    handleplaceorder();
                   }}>Place Order</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cart;
